body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="container"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  [class*="tableStyles"] {
    font-size: small;
    padding: 5px;
  }
}

@media only screen and (min-width: 769px) {
  /* For mobile phones: */
  [class*="container"] {
    padding-right: 150px;
    padding-left: 150px;
  }

  [class*="tableStyles"] {
    padding: 20px;
  }
}

.tableStyles {
  background-color: #333333;
  border: solid 1px white;
  color: #fff;
}

.container {
  padding-bottom: 50px;
  text-align: left;
}

