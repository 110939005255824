@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="container"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  [class*="tableStyles"] {
    font-size: small;
    padding: 5px;
  }
}

@media only screen and (min-width: 769px) {
  /* For mobile phones: */
  [class*="container"] {
    padding-right: 150px;
    padding-left: 150px;
  }

  [class*="tableStyles"] {
    padding: 20px;
  }
}

.tableStyles {
  background-color: #333333;
  border: solid 1px white;
  color: #fff;
}

.container {
  padding-bottom: 50px;
  text-align: left;
}
